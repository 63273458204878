import Prism from "prismjs";

import SimpleEditor, { styles } from "./Components/Editor/editor.jsx";

import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css"; //Example style, you can use another

import React, { useState, useEffect } from "react";

import "./App.css";
import "./Components/Editor/styles.css";

const CODE = "CODE";

function App() {
  const [content, setContent] = useState(`const API_KEY = "...";

async function embedding(text) {
  const response = await fetch("https://api.openai.com/v1/embeddings", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Authorization: ,
    },
    body: JSON.stringify({
      // …
    }),
  });

  return text;
}

async function run() {
  const textOriginal = "Dog";

  const vectorOriginal = await embedding(textOriginal);
  console.log(vectorOriginal);
}

console.clear();
console.log("--- start");
run();
  


`);
  // const [apiKey, setApiKey] = useState(""); // For the input field

  // const prefix = `const API_KEY = '${apiKey}';`;

  // When the component mounts, check if there's any value in local storage
  useEffect(() => {
    // const storedApiKeyValue = localStorage.getItem(API_KEY);
    // if (storedApiKeyValue) {
    //   setApiKey(storedApiKeyValue);
    // }
    const storedCodeValue = localStorage.getItem(CODE);
    if (storedCodeValue) {
      setContent(storedCodeValue);
    }
  }, []);

  const handleInputChange = (value) => {
    setContent(value);
    localStorage.setItem(CODE, value); // Save value in local storage
  };

  // const handleInputValueChange = (e) => {
  //   const value = e.target.value;
  //   setApiKey(value);
  //   localStorage.setItem(API_KEY, value); // Save value in local storage
  // };

  const runCode = () => {
    try {
      // Check for syntax errors
      new Function(content); // This line will throw a SyntaxError if there's a syntax issue

      // No syntax errors, execute the code
      const finalCode = `${content}`;
      const executeCode = new Function(finalCode);

      // Use try...catch inside the generated function
      executeCode();
    } catch (error) {
      console.error("Error executing code:", error);
    }
  };

  const keyInputHandler = (e) => {
    if ((e.metaKey || e.ctrlKey) && e.key === "Enter") {
      e.preventDefault();
      // Check if the "Command + Enter" (Mac) or "Ctrl + Enter" (Windows/Linux) is pressed
      runCode(); // Run the code
      // e.preventDefault(); // Prevent the default behavior of a new line in a textarea
    }
  };

  return (
    <div className="App">
      <br />
      <button onClick={runCode}>Run Code</button> {/* Button to run code */} (CMD + Enter | ⌘ + Enter)
      <br />
      <br />
      <div className="editor-section">
        <div className="editor">
          <SimpleEditor
            className="editor__editor code"
            preClassName="editor__textarea-pre"
            textareaClassName="editor__textarea-textarea"
            onValueChange={handleInputChange}
            value={content}
            highlight={(code) => highlight(code, languages.js)}
            onKeyDown={keyInputHandler}
            style={
              {
                // fontFamily: '"Fira code", "Fira Mono", monospace',
                // fontSize: 12,
              }
            }
          />
        </div>
      </div>
    </div>
  );
}

export default App;
